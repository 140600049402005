import { instance } from './instance';

const { get, post, delete: deleteReq } = instance;

export const getAllSections = () => get('/info/admin/sections');
export const getCurrentSection = (sectionId: number) =>
  get(`/info/admin/sections/${sectionId}`);
export const getCurrentSectionBlocks = (sectionId: number) =>
  get(`/info/admin/sections/${sectionId}/blocks`);

export const getAllDetails = () => get(`/info/admin/details`);

export const postEditBlock = (data: any) =>
  post('/info/admin/sections/blocks/update', data);

export const postCreateBlock = (data: any) =>
  post('/info/admin/sections/blocks/add', data);

export const postEditDetailsScreen = (data: any) =>
  post('/info/admin/details', data);

export const postStartBlocks = (data: any) =>
  post('/info/admin/sections/1/children', data);

export const deleteBlock = (id: number) =>
  deleteReq(`/info/admin/sections/blocks/${id}`);

export const deleteEditDetailsScreen = (id: number) =>
  deleteReq(`/info/admin/details/${id}`);

/* export const getAllSections = () => get('/info/admin/sections');
export const getAllSections = () => get('/info/admin/sections');
export const getAllSections = () => get('/info/admin/sections'); */
