import { makeStyles } from '@material-ui/core';

export const useStyledBlocksList = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: 'calc(100% - 8px)',
    marginTop: 16,

    '& > div': {
      margin: '8px',
    },
  },
});
